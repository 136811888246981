.component {
  align-items: flex-start;
  background-color: var(--color-gray);
  background-image: url('/images/rebrand/hp-overview-mobile.png');
  background-position-x: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  font-family: theme('fontFamily.beatrice-light');
  min-height: 700px;
  padding: theme('spacing.8x') theme('spacing.3xs');
  position: relative;
}

.heading {
  font-size: 40px;
  margin: 0;
  margin-top: 120px;
  text-align: center;
  white-space: pre-line;
}

.subhead {
  bottom: theme('spacing.4x');
  font-family: theme('fontFamily.beatrice-regular');
  font-size: 20px;
  left: 0;
  margin-top: 100px;
  position: absolute;
  right: 0;
  text-align: center;
}

@screen sm {
  .subhead {
    font-size: 22px;
  }
}

@screen md {
  .heading {
    font-size: 44px;
  }

  .subhead {
    font-size: 24px;
  }
}

@screen lg {
  .component {
    background-image: url('/images/rebrand/hp-overview.jpg');
  }

  .heading {
    font-size: 48px;
    text-align: unset;
  }

  .subhead {
    font-size: 28px;
    position: relative;
    text-align: unset;
  }
}

@screen xl {
  .heading {
    font-size: 52px;
  }

  .subhead {
    font-size: 28px;
  }
}

.component {
  background-color: var(--color-pine-light);
  padding: theme('spacing.4x') theme('spacing.2x') 0;
}

.content {
  align-items: center;
  background-color: var(--color-pine-light);
  display: flex;
  flex-flow: row nowrap;
  gap: theme('spacing.8x');
  overflow: auto;
  padding: theme('spacing.4x');
  transform: translate(0);
}

.brand {
  cursor: pointer;
  padding: 0;
  zoom: 0.6;
}

.heading {
  font-family: theme('fontFamily.bradford-light-italic');
  font-size: 32px;
  line-height: 1.2;
  margin: 0;
  text-align: center;
}

.heading span {
  display: block;
}

@screen sm {
  .heading span {
    display: initial;
  }
}

@screen md {
  .brand {
    zoom: 0.8;
  }

  .heading {
    font-size: 48px;
  }
}

@screen lg {
  .component {
    padding: theme('spacing.6x') theme('spacing.2x') 0;
  }

  .brand {
    zoom: 1;
  }

  .content {
    gap: theme('spacing.8x');
    padding: theme('spacing.10x') theme('spacing.4x');
  }

  .heading {
    font-size: 64px;
  }
}

.component {
  background-color: var(--color-gray);
  padding: theme('spacing.6x') theme('spacing.4x') theme('spacing.8x');
  text-align: center;
}

.container {
  flex-direction: column;
  gap: theme('spacing.4x');
}

.copy {
  display: none;
  font-size: theme('fontSize.28');
}

.copyMobile {
  font-size: 16px;
  line-height: 1.6;
  margin-top: 100px;
}

.cta {
  color: theme('colors.forest');
  margin-top: theme('spacing.4x');
}

.heading {
  font-size: theme('fontSize.32');
  margin: 0;
}

.image {
  margin: 0 auto;
  max-width: 300px;
  width: 80%;
}

.sticky {
  bottom: -20%;
  left: 50%;
  position: absolute;
  text-align: left;
  width: 130px;
}

.right {
  position: relative;
}

@media screen and (max-width: 320px) {
  .copyMobile {
    margin-top: 80px;
  }

  .heading {
    font-size: 26px;
  }
}

@screen md {
  .copyMobile {
    font-size: 24px;
    margin-top: 140px;
  }

  .heading {
    font-size: 40px;
  }

  .sticky {
    bottom: -25%;
    left: 48%;
    width: 220px;
  }
}

@screen lg {
  .component {
    padding: 100px theme('spacing.4x');
    text-align: unset;
  }

  .container {
    flex-direction: row;
    gap: unset;
    text-align: unset;
  }

  .copy {
    display: block;
    font-family: theme('fontFamily.beatrice-light');
    font-size: 24px;
    max-width: 90%;
  }

  .copyMobile {
    display: none;
  }

  .heading {
    font-size: 50px;
    margin-bottom: 120px;
  }

  .image {
    max-width: 100%;
    width: 100%;
  }

  .right {
    max-width: 100%;
    min-width: 370px;
  }

  .sticky {
    bottom: -15%;
    left: 39%;
    width: 240px;
  }
}

@screen xl {
  /* @media (min-width: 1480px) { */
  .copy {
    font-size: 30px;
  }

  .heading {
    font-size: 55px;
  }

  .right {
    min-width: 420px;
  }
}

.component {
  background-color: theme('colors.forest');
  color: theme('colors.white');
  font-family: theme('fontFamily.beatrice-light');
  padding: theme('spacing.8x') theme('spacing.4x') theme('spacing.10x');
}

.bordered {
  border-bottom: 2px solid theme('colors.white');
  border-top: 2px solid theme('colors.white');
  color: theme('colors.white');
  display: block;
  font-size: theme('fontSize.20');
  margin: theme('spacing.4x') auto theme('spacing.6x');
  padding: theme('spacing.3xs') 0;
}

.bordered span,
.heading span {
  display: block;
}

.heading {
  font-size: theme('fontSize.32');
  margin: 0;
  margin-bottom: theme('spacing.1x');
}

.subhead {
  color: theme('colors.pine');
  font-family: theme('fontFamily.beatrice-light');
  font-size: 22px;
}

.subhead span {
  display: block;
}

@screen xs {
  .subhead {
    margin: auto;
  }
}

@screen md {
  .component {
    font-size: theme('fontSize.24');
    padding: 140px theme('spacing.4x') 180px;
  }

  .bordered {
    font-size: 43px;
    margin: theme('spacing.8x') auto theme('spacing.10x');
    max-width: 90%;
  }

  .bordered span,
  .heading span {
    display: inline;
  }

  .heading {
    font-size: 55px;
    margin-bottom: theme('spacing.1x');
  }

  .subhead {
    font-size: 24px;
  }

  .subhead span {
    display: inline;
  }
}

.component {
  display: inline-block;
  font-family: theme('fontFamily.give-you-glory');
  font-size: 2.2em;
  line-height: 1.25;
  position: relative;
  z-index: 8;
}

.contents {
  background-repeat: no-repeat;
  background-size: contain;
  padding: theme('spacing.3xs');
  position: relative;
  z-index: 1;
}

.image {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 0;
}

.flipped .image {
  transform: scaleX(-1);
}

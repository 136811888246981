.component {
  background-color: theme('colors.forest');
  padding: theme('spacing.1x') theme('spacing.3xs') theme('spacing.6x');
  position: relative;
}

.experiences {
  flex-direction: column;
  gap: theme('spacing.6x');
  margin: 0 theme('spacing.3x');
  max-width: 300px;
  opacity: 1;
}

.experiences p {
  color: theme('colors.white');
  font-size: theme('fontSize.16');
}

.heading {
  align-items: center;
  color: theme('colors.white');
  display: flex;
  font-family: theme('fontFamily.bradford-light-italic');
  font-size: theme('fontSize.36');
  justify-content: center;
  margin: 0 0 theme('spacing.1x');
}

.hearts {
  display: none;
  position: absolute;
}

.image {
  width: 20px;
}

.expHeading {
  border-bottom: 2px solid theme('colors.seaSalt');
  font-size: 22px;
  margin: theme('spacing.2x') 0 theme('spacing.2xs');
  max-width: 80%;
  padding-bottom: theme('spacing.3x');
  white-space: nowrap;
}

.expHeading span {
  display: block;
}

.logo {
  width: 120px;
}

.sticky {
  bottom: -5%;
  left: 35%;
  margin-bottom: theme('spacing.3xs');
  margin-top: theme('spacing.8x');
  position: absolute;
  zoom: 0.8;
}

.subhead {
  font-size: theme('fontSize.32');
  margin-bottom: theme('spacing.10x');
  margin-top: theme('spacing.10x');
  text-align: center;
}

@media screen and (max-width: 320px) {
  .heading {
    font-size: 30px;
  }
}

@screen sm {
  .component {
    padding: theme('spacing.1x') theme('spacing.4x') theme('spacing.6x');
  }
}

@screen md {
  .component {
    padding: theme('spacing.4x') theme('spacing.4x') 140px;
  }

  .heading {
    font-size: 60px;
  }

  .logo {
    width: 200px;
  }

  .expHeading {
    font-size: 18px;
  }

  .experiences {
    flex-direction: row;
    gap: 5%;
    margin: 0;
    max-width: unset;
  }

  .experience {
    width: 25%;
  }

  .experience:nth-of-type(2) {
    margin-top: 80px;
  }

  .experience:nth-of-type(3) {
    margin-top: 160px;
  }

  .experience:nth-of-type(4) {
    margin-top: 240px;
  }

  .hearts {
    bottom: 18%;
    display: unset;
    left: 48%;
  }

  .image {
    width: 30px;
  }

  .subhead {
    font-size: 40px;
    margin: 100px 0 0;
    text-align: unset;
  }

  .sticky {
    bottom: 10%;
    left: 1%;
    margin-top: -20px;
    position: relative;
    zoom: 1;
  }
}

@screen lg {
  .component {
    padding: theme('spacing.8x') theme('spacing.4x') 140px;
  }

  .expHeading {
    font-size: 30px;
  }

  .heading {
    font-size: 70px;
  }

  .hearts {
    bottom: 20%;
  }

  .logo {
    width: 228px;
  }

  .subhead {
    font-size: 55px;
  }
}

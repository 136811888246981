.component {
  background-color: theme('colors.forest');
  min-height: calc(100vh - var(--header-height));
  padding: theme('spacing.4x');
}

.container {
  gap: theme('spacing.4x');
  justify-content: center;
  text-align: center;
  width: 100%;
}

.cta {
  color: theme('colors.white');
  margin-top: theme('spacing.10x');
}

.heading {
  font-size: 40px;
  margin: 0;
  width: 100%;
}

.left {
  flex: 1 0 25%;
  margin: theme('spacing.6x') auto;
}

.left svg {
  margin: auto;
  max-width: 100%;
  width: 200px;
}

.right {
  flex: 1 0;
  margin-bottom: 140px;
}

@screen md {
  .component {
    background-color: theme('colors.forest');
    padding: 120px theme('spacing.4x') theme('spacing.10x');
  }

  .container {
    flex-direction: row;
    text-align: unset;
  }

  .left {
    margin-top: -8px;
    max-width: 28%;
  }

  .left svg {
    padding-right: theme('spacing.3x');
    width: 300px;
  }

  .right {
    margin-bottom: theme('spacing.10x');
  }
}

@screen md {
  .component {
    align-items: center;
    display: flex;
    min-height: calc(100vh - 80px);
  }
}

@screen lg {
  .heading {
    font-size: 60px;
  }
}

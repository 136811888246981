.sticky {
  opacity: 1;
  position: absolute;
}

.stickyOne {
  bottom: 20px;
  right: 2%;
  z-index: 1;
}

.stickyTwo {
  bottom: -70px;
  right: 15%;
  z-index: 2;
}

@media screen and (max-width: 320px) {
  .stickyOne div,
  .stickyTwo div {
    font-size: 20px;
  }

  .stickyOne {
    bottom: 10px;
  }
}

@screen md {
  .stickyOne {
    bottom: 40px;
  }

  .stickyTwo {
  }
}

@screen lg {
  .stickyOne {
    right: 5%;
  }

  .stickyTwo {
    left: unset;
    right: 15%;
  }
}

@screen xl {
  .stickyOne {
    right: -5%;
  }

  .stickyTwo {
    left: unset;
    right: 5%;
  }
}

.component {
  padding: 0;
}

.container {
  align-items: center;
  flex-direction: column;
  gap: theme('spacing.2x');
  justify-content: center;
  max-width: 600px;
}

.heading {
  font-size: theme('fontSize.32');
  text-align: center;
}

.image {
  text-align: center;
}

.image img {
  max-width: 200px;
}

.instagram {
  color: #777;
  font-family: theme('fontFamily.beatrice-light');
  font-size: theme('fontSize.12');
}

.products {
  display: flex;
  flex-wrap: wrap;
  gap: theme('spacing.4x');
  justify-content: space-around;
  margin-top: theme('spacing.4x');
}

.product {
  display: flex;
  flex: 0 0 44%;
  flex-direction: column;
  min-width: 120px;
  position: relative;
}

.product p {
  font-size: theme('fontSize.12');
  line-height: 1.2;
  margin-left: theme('spacing.1x');
  text-align: left;
}

.productHeading {
  font-family: theme('fontFamily.central-no1-light');
  font-size: 24px;
  margin: 0;
  padding-left: theme('spacing.1x');
}

.square {
  background-color: theme('colors.white');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: theme('spacing.1x');
  height: 0;
  padding: theme('spacing.1x') theme('spacing.1x') 100%;
}

.subhead {
  font-size: theme('fontSize.14');
  line-height: 1.6;
  margin: theme('spacing.2x') 0 0;
}

/**
 * Box Shadows
 */
.product:nth-of-type(1) .square {
  box-shadow: theme('spacing.1xs') theme('spacing.1xs') theme('colors.pine');
}

.product:nth-of-type(2) .square {
  box-shadow: theme('spacing.1xs') theme('spacing.1xs') theme('colors.turmeric');
}

.product:nth-of-type(3) .square {
  box-shadow: theme('spacing.1xs') theme('spacing.1xs') theme('colors.forest');
}

.product:nth-of-type(4) .square {
  box-shadow: theme('spacing.1xs') theme('spacing.1xs') theme('colors.rust');
}

/**
 * Numbers
 */
.product:nth-of-type(1) {
  color: theme('colors.pine');
}

.product:nth-of-type(2) {
  color: theme('colors.turmeric');
}

.product:nth-of-type(3) {
  color: theme('colors.forest');
}

.product:nth-of-type(4) {
  color: theme('colors.rust');
}

@media (max-width: 320px) {
  .products {
    gap: theme('spacing.3xs');
  }

  .product {
    min-width: unset;
  }
}

@screen sm {
  .products {
    margin-top: theme('spacing.4x');
  }

  .subhead {
    font-size: theme('fontSize.24');
    margin: theme('spacing.4x') 0;
  }
}

@screen md {
  .container {
    margin: theme('spacing.8x') auto 0;
  }

  .heading {
    font-size: 40px;
  }

  .image {
    max-width: 60%;
  }

  .image img {
    max-width: 100%;
  }

  .instagram {
    font-size: theme('fontSize.24');
    margin: 10px 0;
  }

  .productHeading {
    font-size: 40px;
  }

  /**
   * Box Shadows (larger on larger screens)
   */
  .product:nth-of-type(1) .square {
    box-shadow: theme('spacing.1x') theme('spacing.1x') theme('colors.pine');
  }

  .product:nth-of-type(2) .square {
    box-shadow: theme('spacing.1x') theme('spacing.1x') theme('colors.turmeric');
  }

  .product:nth-of-type(3) .square {
    box-shadow: theme('spacing.1x') theme('spacing.1x') theme('colors.forest');
  }

  .product:nth-of-type(4) .square {
    box-shadow: theme('spacing.1x') theme('spacing.1x') theme('colors.rust');
  }
}

@screen lg {
  .component {
    padding: 0;
    text-align: unset;
  }

  .container {
    align-items: unset;
    flex-direction: row;
    gap: theme('spacing.8x');
    justify-content: unset;
    margin: theme('spacing.8x') auto 0;
    max-width: unset;
  }

  .heading {
    font-size: 55px;
    text-align: unset;
  }

  .image {
    max-width: 400px;
  }

  .products {
    margin-top: theme('spacing.2x');
  }

  .product {
    max-width: 20%;
  }

  .subhead {
    font-size: 30px;
    margin: 0;
  }
}

@screen xl {
  .image {
    max-width: unset;
    width: 840px;
  }
}

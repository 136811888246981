.component {
  background-color: theme('colors.pine');
  color: theme('colors.white');
  padding: theme('spacing.8x') theme('spacing.3xs');
}

.copy {
  padding-left: theme('spacing.3x');
  padding-right: theme('spacing.3x');
}

.cta {
  width: 100%;
}

.founders {
  line-height: 1.2;
  margin-bottom: theme('spacing.6x');
  min-width: 40%;
}

.founder {
  font-family: theme('fontFamily.give-you-glory');
  font-size: 16px;
  transition: transform 200ms ease-out;
}

.heading {
  font-size: theme('fontSize.32');
  margin: 0 0 theme('spacing.4x');
  text-align: center;
}

.image {
  margin: theme('spacing.2x') auto theme('spacing.2x') -10px;
  max-width: unset;
  transition: transform 200ms ease-out;
  width: calc(100% + 20px);
}

.reflow {
  align-items: start;
  flex-direction: column;
  justify-content: center;
}

@screen sm {
  .cta {
    width: unset;
  }
}

@screen md {
  .component {
    padding: 100px theme('spacing.4x');
  }

  .copy {
    margin-top: theme('spacing.6x');
    padding-left: 0;
    padding-right: 0;
  }

  .founders {
    margin-bottom: 0;
  }

  .founder:first-of-type {
    padding-left: 0;
  }

  .founder:last-of-type {
    margin-right: -80px;
  }

  .heading {
    font-size: 40px;
    margin: 0 0 theme('spacing.6x');
  }

  .image {
    margin: theme('spacing.2x') 0 theme('spacing.2x') -5%;
  }

  .reflow {
    flex-direction: row;
    gap: theme('spacing.10x');
    text-align: unset;
  }
}

@screen lg {
  .copy {
    font-size: 30px;
    margin-top: theme('spacing.10x');
  }

  .founder {
    font-size: 30px;
  }

  .heading {
    font-size: 55px;
  }

  .image {
    max-width: unset;
    width: 105%;
  }

  .reflow {
    gap: 100px;
  }
}

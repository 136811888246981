.component {
  background-color: var(--color-gray);
  padding: theme('spacing.10x') theme('spacing.4x');
}

.image {
  max-width: 40%;
  text-align: center;
}

.image img {
  margin-bottom: theme('spacing.2x');
}

@screen xl {
  .component {
    padding: theme('spacing.10x') 0;
  }
}
